

.AboutContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.screenshot {
    width: 200px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    margin-top: 20px;
}

.about-header {
    font-size: 12px;
}