@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400&display=swap');

.App {
  text-align: center;
}

.header {
  background-color: white;
  color: #006747;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgrey;
}

.footer {
  margin-top: 20px;
  padding: 20px;
  font-size: 0.8em;
  color: grey;
  text-align: center;
}

.title {
  font-family: 'Heebo', sans-serif;
}

.navbar-brand {
  font-family: 'Heebo', sans-serif;
  font-size: 2.5rem !important;
  color: #006747 !important;
  padding: 0px !important;
}

.logo {
  width: 110px;
  margin-left: -15px;
  margin-top: 10px;
  margin-bottom: 14px;
}