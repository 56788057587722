.AddScorecard {
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    background: white;
}

.Test {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.Test-inner {
    display: flex;
    flex-direction: row;
}

.menu {
    position: "absolute" !important;
    z-index: 1000;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #cccccc;
    background-color: white;
  }
  
.item {
    padding: 2px 6px;
    cursor: default;
}

.item-highlighted {
    color: white;
    background-color: #4095bf;
}

.item-header {
    background-color: #eeeeee;
    color: #454545;
    font-weight: bold;
}

.auto-course {
    padding-left:10px;
}

.auto-metadata {
    padding-left:10px;
    font-size: 0.7em;
}

.auto-container {
    margin-bottom: 10px;
}